import React, { useState, useEffect } from 'react';
import { Button, Select, List, message, Card, Typography, Divider } from 'antd';
import {
  getAppHierarchy,
  addAppHierarchy,
  deleteAppHierarchy,
  getUserListAPI,
} from './ducks/service'; // Adjust the path

const { Option } = Select;
const { Title } = Typography;

interface ManagerData {
  id: string;
  name: string;
}

export default function ApprovalHierarchyForm() {
  const [selectedManagers, setSelectedManagers] = useState<ManagerData[]>([]);
  const [existingHierarchy, setExistingHierarchy] = useState<ManagerData[]>([]);
  const [dynamicFinanceManagers, setDynamicFinanceManagers] = useState<
    ManagerData[]
  >([]);
  const [loading, setLoading] = useState(false);

  // Fetch existing hierarchy and users on component mount
  useEffect(() => {
    const fetchHierarchyAndManagers = async () => {
      try {
        setLoading(true);

        // Fetch the existing hierarchy
        const hierarchyResponse = await getAppHierarchy();

        // Fetch users and filter out finance managers
        const userResponse = await getUserListAPI();
        const financeManagersList = userResponse.data
          .filter((user: any) => user.rolesAssigned.includes('Finance Manager')) // Filter for "Finance Manager"
          .map((user: any) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
          }));

        setDynamicFinanceManagers(financeManagersList);

        // Match the existing hierarchy IDs with the finance managers list to get their names
        console.log('hierarchyResponse: ', hierarchyResponse);
        let matchedHierarchy =
          hierarchyResponse?.hierarchy &&
          hierarchyResponse?.hierarchy.map((managerId: string) => {
            const manager = financeManagersList.find(
              (m: ManagerData) => m.id === managerId, // Explicitly define the type for 'm'
            );
            return manager || { id: managerId, name: 'Unknown Manager' }; // Fallback in case no manager is found
          });

        if (!matchedHierarchy) {
          matchedHierarchy = [];
        }
        setExistingHierarchy(matchedHierarchy);
      } catch (error) {
        message.error('Failed to fetch hierarchy or finance managers.');
        console.error('API Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHierarchyAndManagers();
  }, []);

  // Handle finance manager selection
  const handleSelectManager = (managerId: string) => {
    const selectedManager = dynamicFinanceManagers.find(
      (manager) => manager.id === managerId,
    );
    if (selectedManager && !selectedManagers.some((m) => m.id === managerId)) {
      setSelectedManagers([...selectedManagers, selectedManager]);
    }
  };

  // Handle removing a manager from the hierarchy
  const handleRemoveManager = (managerId: string) => {
    setSelectedManagers(
      selectedManagers.filter((manager) => manager.id !== managerId),
    );
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (selectedManagers.length === 0) {
      message.error('Please select at least one finance manager.');
      return;
    }

    const hierarchy = selectedManagers.map((manager) => manager.id);

    try {
      const response = await addAppHierarchy(hierarchy); // Call the API with selected hierarchy
      message.success(response.message);
      console.log('API response:', response);
    } catch (error) {
      message.error('Failed to create hierarchy.');
      console.error('API error:', error);
    }
  };

  // Handle deleting the existing hierarchy
  const handleDeleteHierarchy = async () => {
    try {
      setLoading(true);
      await deleteAppHierarchy(); // Call the delete API
      message.success('Hierarchy deleted successfully');
      setExistingHierarchy([]); // Clear existing hierarchy after deletion
    } catch (error) {
      message.error('Failed to delete hierarchy.');
      console.error('API error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Title level={3}>Approval Hierarchy</Title>

      <Divider>Existing Hierarchy</Divider>
      <Card loading={loading}>
        {existingHierarchy.length > 0 ? (
          <>
            <List
              dataSource={existingHierarchy}
              renderItem={(manager) => (
                <List.Item>
                  <Typography.Text>{manager.name}</Typography.Text>
                </List.Item>
              )}
            />
            <Button
              danger
              onClick={handleDeleteHierarchy}
              style={{ marginTop: '20px' }}
            >
              Delete Existing Hierarchy
            </Button>
          </>
        ) : (
          <Typography.Text type="secondary">
            No existing hierarchy found.
          </Typography.Text>
        )}
      </Card>

      <Divider>Select New Finance Managers</Divider>
      <Select
        placeholder="Select a finance manager"
        style={{ width: 300 }}
        onSelect={handleSelectManager}
      >
        {dynamicFinanceManagers.map((manager) => (
          <Option key={manager.id} value={manager.id}>
            {manager.name}
          </Option>
        ))}
      </Select>

      <Divider>Selected Managers (New Hierarchy)</Divider>
      <List
        dataSource={selectedManagers}
        renderItem={(manager) => (
          <List.Item
            actions={[
              <Button danger onClick={() => handleRemoveManager(manager.id)}>
                Remove
              </Button>,
            ]}
          >
            {manager.name}
          </List.Item>
        )}
      />

      <Button
        type="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
        disabled={selectedManagers.length === 0}
      >
        Submit New Hierarchy
      </Button>
    </div>
  );
}
