/* eslint-disable */
import { ReactElement, useState } from 'react';
import { Col, Row, DatePicker, Select, Input, Button } from 'antd';
import { EditOutlined, DownloadOutlined, PrinterOutlined, FilterOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { getDashboardMetrics } from '../../modules/DashboardView/ducks2/slice'

const { Search } = Input;

export default function HeaderFilterControlDashboard(): ReactElement {
  const dispatch = useDispatch(); // Initialize dispatch
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  // Handle date change
// Handle date change
const handleDateChange: any = (dates: [Dayjs, Dayjs] | null) => {
  if (dates && dates.length === 2) {
    const start = dates[0].format('YYYY-MM-DD');
    const end = dates[1].format('YYYY-MM-DD');
    setStartDate(start);
    setEndDate(end);

    // Dispatch the action to get metrics with selected dates
    dispatch(
      getDashboardMetrics({
        startDate: start || undefined, // Use undefined if startDate is not provided
        endDate: end || undefined,     // Use undefined if endDate is not provided
      }) as any // Ensure proper typing by adding `as any` if there's a type mismatch
    );
  } else {
    setStartDate(null);
    setEndDate(null);

    // Dispatch without dates (optional)
    dispatch(getDashboardMetrics({}) as any);
  }
};

  return (
    <Row gutter={[16, 16]} justify="space-between" style={{ marginTop: '12px' }}>
      {/* First Col: Start aligned set of fields */}
      <Col>
        <Row gutter={[2, 16]}>
          <Col span={2} className="ut-mb-s">
            <span className="ut-label">Filter By:</span>
          </Col>
          <Col span={4}>
            <DatePicker.RangePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              defaultValue={[dayjs().subtract(1, 'month'), dayjs()]}
            />
          </Col>
          <Col>
            <Select
              showSearch
              defaultValue="Location"
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[{ value: '1', label: 'Not Identified' }]}
            />
          </Col>
          <Col>
            <Select
              showSearch
              defaultValue="Category / Brand"
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[{ value: '1', label: 'Not Identified' }]}
            />
          </Col>
          <Col>
            <Search allowClear enterButton placeholder="Type to search..." />
          </Col>
          <Col>
            <Button
              icon={<FilterOutlined />}
              style={{ border: '1px solid #d9d9d9', backgroundColor: 'white', color: '#5c5c5c' }}
            >
              Filters
            </Button>
          </Col>
        </Row>
      </Col>

      {/* Second Col: End aligned button */}
      <Col>
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* Edit Button */}
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#001A40', border: 'none' }}
          />

          {/* Export Button */}
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{ backgroundColor: '#003E92', border: 'none' }}
          >
            Export
          </Button>

          {/* Print Button */}
          <Button icon={<PrinterOutlined />} style={{ border: '1px solid #d9d9d9' }}>
            Print
          </Button>
        </div>
      </Col>
    </Row>
  );
}
