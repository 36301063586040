/* eslint-disable */

import { IDashboardMetricsReponse, IDashboardState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import * as dashboardSource from './services';

export const initialState: IDashboardState = {
  dashboardMetrics: {},
  bargraph: {},
  loading: false,
  error: '',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardMetricsInProgress(state) {
      state.loading = true;
    },
    getDashboardMetricsSuccess(
      state,
      { payload }: PayloadAction<IDashboardMetricsReponse>,
    ) {
      state.loading = false;
      state.dashboardMetrics = payload.data;
    },
    getDashboardMetricsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getDashboardMetricsCategoryInProgress(state) {
      state.loading = true;
    },
    getDashboardMetricsCategorySuccess(
      state,
      { payload }: PayloadAction<IDashboardMetricsReponse>,
    ) {
      state.loading = false;
      state.bargraph = payload.data;
    },
    getDashboardMetricsCategoryFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
  },
});

export const {
  actions: dashboardActions,
  reducer: dashboardReducer,
  name: sliceKey,
} = dashboardSlice;

export const {
  getDashboardMetricsInProgress,
  getDashboardMetricsSuccess,
  getDashboardMetricsFailed,
  getDashboardMetricsCategoryInProgress,
  getDashboardMetricsCategorySuccess,
  getDashboardMetricsCategoryFailed,
} = dashboardSlice.actions;

export const getDashboardMetrics = ({ startDate = '', endDate = '' } = {}) => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getDashboardMetricsInProgress());
  try {
    const data = await dashboardSource.getDashboardMetricsApi({startDate, endDate});
    dispatch(getDashboardMetricsSuccess(data));
    dispatch(loadingActions.hideLoader());
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getDashboardMetricsFailed({ error }));
  }
};
export const getDashboardMetricsCategory =
  () => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getDashboardMetricsCategoryInProgress());
    try {
      const data = await dashboardSource.getDashboardMetricsCategoryApi();
      dispatch(getDashboardMetricsCategorySuccess(data));
      dispatch(loadingActions.hideLoader());
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getDashboardMetricsCategoryFailed({ error }));
    }
  };
