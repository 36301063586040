/* eslint-disable */

import React from 'react';
import { Progress } from 'antd';
import './index.css'; // Ensure to add this for custom CSS

const FinancialAnalysis: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="outer-circle">
      <Progress
        type="circle"
        percent={data}
        strokeColor="#3268C7" // Custom color for the inner circle
        format={(percent) => (
          <div>
            <div style={{ fontSize: '16px', color: '#5c5c5c' }}>Utilised</div>
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{percent}%</div>
          </div>
        )}
        strokeWidth={4}
        width={200} // Adjust the width to increase size (height and width)
      
      />
    </div>
  );
};

export default FinancialAnalysis;
