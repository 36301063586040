/* eslint-disable */

import { ReactElement , useEffect , useState   } from 'react';
import {
  Col,
  Row,
  Card,
  Statistic,
  Switch,
  DatePicker,
  Select,
  TabsProps,
  Input,
  Button,
  Tabs,
  Spin
} from 'antd';
import {
  PlusOutlined ,
  CaretUpOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';


import ProductPerformance from './productPerformance';
import { Line, Column } from '@ant-design/plots';
import DashboardLayout from './calendar';
import CategoryBrandAnalysis from './categoryBrandAnalysis';
import RetailerPerformance from './retailerPerformance';
import FinancialAnalysis from './financialAnalysis';
import ChannelPerformance from './channelPerformance';
import SupplyChainImpact from './supplyChainImpect';
import SalesAndRevenueTrends from './salesAndRevenueTrend';
import TopPerformingPromotions from './topPerformingPromotions';
import Filters from './filterBy';
import {
  getDashboardMetrics,
  getDashboardMetricsCategory,
} from './ducks2/slice';

const DashboardView2 = (): ReactElement => {

  const dispatch: any = useAppDispatch();
  const {
    dashboardView: { dashboardMetrics, bargraph },
  } = useAppSelector((state: any) => state);
  
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<{ startDate: string | undefined; endDate: string | undefined }>({
    startDate: undefined,
    endDate: undefined,
  });
  

    // Function to handle date changes from the Filters component
 // Function to handle date changes from the Filters component
const handleDateChange = (startDate: string | null, endDate: string | null) => {
  // Convert null values to undefined before passing them to setSelectedDates
  setSelectedDates({ 
    startDate: startDate || undefined, 
    endDate: endDate || undefined 
  });

  // Pass the dates to your API call here if necessary
  dispatch(getDashboardMetrics({ 
    startDate: startDate || undefined, 
    endDate: endDate || undefined 
  }));
};

  useEffect(() => {
    // If dashboardMetrics is empty, dispatch the action to get data
    if (Object.keys(dashboardMetrics).length === 0) {
      dispatch(
        getDashboardMetrics({
          startDate: '2024-01-01',  // Start date as 1 Jan 2024
          endDate: '2024-10-30',    // End date as 30 Oct 2024
        })).then(() => {
        // Set loading to false after the data has been fetched
        setLoading(false);
      });
    } else {
      setLoading(false); // If the data is already available, no need to load
    }
  }, [dashboardMetrics, dispatch]);



  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Overview',
      children: (
        <>
              {loading ? (
        // Show a loader while data is being fetched
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
<>
{/* <Filters onDateChange={handleDateChange} /> */}

          <div className="dashboard-view-wrapper" style={{ padding: '20px' }}>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%', // Dynamic height
                    borderRadius: '0px',

                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Total Revenue
                          </span>
                        }
                        value={dashboardMetrics?.totalrevenue || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                        prefix="$"
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Second Column - Overall ROI */}
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Overall ROI
                          </span>
                        }
                        value={dashboardMetrics?.overAllRoi || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                        prefix="$"
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Third Column - Active Promotion */}
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Active Promotion
                          </span>
                        }
                        value={dashboardMetrics?.activePromotion || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Fourth Column - Market Share */}
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Market Share
                          </span>
                        }
                        value={10}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                        suffix="%"
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* First Column - Total Revenue */}
              <Col xs={24} sm={12} lg={6}>

                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%', // Dynamic height
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                           Actual vs Planned Revenue
                          </span>
                        }
                        value={dashboardMetrics?.actualvsPlannedRevenue || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                        prefix="$"
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Second Column - Overall ROI */}
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Target ROI
                          </span>
                        }
                        value={dashboardMetrics?.targetRoi || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                        prefix="$"
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Third Column - Active Promotion */}
              <Col xs={24} sm={12} lg={6}>
                <Card
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Statistic
                        title={
                          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                            Total Budget
                          </span>
                        }
                        value={dashboardMetrics?.totalBudget || 0}
                        precision={0}
                        valueStyle={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#1E1E1E',
                        }}
                      />
                    </Col>
                    <Col>
                      <Statistic
                        value={8.5}
                        precision={1}
                        valueStyle={{
                          color: '#52C41A',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                        prefix={<CaretUpOutlined />}
                        suffix="%"
                      />
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                        vs last 7 days
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* Fourth Column - Market Share */}
              <Col xs={24} sm={12} lg={6}>
              <Card
      bordered={true}
      style={{
        borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '100%',
        backgroundColor: '#E6F7FF', // Light blue background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #1890ff',
      }}
    >
      <Button
        type="text"
        icon={<PlusOutlined />}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        Add Custom
      </Button>
    </Card>
              </Col>
            </Row>

            {/* Second Row - Sales and Revenue Trend */}
            <Row gutter={[16, 16]} style={{ marginTop: '18px' }}>
              <Col span={12}>
                <Card
                  title="Sales and Revenue Trend"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '351px', // Use Figma dimensions,
                  }}
                >
                  <SalesAndRevenueTrends data={dashboardMetrics.salesAndRevenueTrends|| []}/>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span>Top Performing Promotions</span>
                      <Switch defaultChecked />
                    </div>
                  }
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '351px', // Use Figma dimensions
                  }}
                >
                 <TopPerformingPromotions data={dashboardMetrics.topPerformingPromotion|| []}/>
                </Card>
              </Col>
            </Row>
           
            <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
              <Col xs={24} sm={12} lg={6}>
               
                <Card
                  title="Product Performance"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '312px', // Dynamic height
                  }}
                >
                  <Row justify="space-between" align="middle">
                    {/* Use Row for layout */}
                    <Col>
                      <ProductPerformance data={dashboardMetrics.productPerformance|| []}/>

                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                {' '}
                {/* Responsive span */}
                <Card
                  title="Category/Brand Analysis"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '312px', // Dynamic height
                  }}
                >
                  {/* <Row justify="space-between" align="middle"> */}
                  <Col>
                  <CategoryBrandAnalysis data={dashboardMetrics.categoryBrandAnalysis || []}/>
                  </Col>
                  {/* </Row> */}
                </Card>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Card
                  title="Retailer Performance"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '312px', // Dynamic height
                  }}
                >
                    <RetailerPerformance data={dashboardMetrics.retailerPerformance|| []}/>

                
                </Card>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Card
                  title="Financial Analysis"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '312px',
                  }}
                >
                  <FinancialAnalysis data={dashboardMetrics.financialAnalysis|| []} />
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
              <Col span={12}>
                {/* Responsive span */}
                <Card
                  title="Channel Performance"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '350px', // Dynamic height
                  }}
                >
                 
                    <ChannelPerformance data={dashboardMetrics.channelPerformance|| []} />
                 
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  title="Supply Chain Impact"
                  style={{
                    borderRadius: '0px',
                    boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                    height: '350px', // Dynamic height
                  }}
                >
                  <Col>
                  <SupplyChainImpact data={dashboardMetrics.supplyChainImpact|| []} />
                  </Col>
                </Card>
              </Col>
            </Row>
          </div>
          </>

)}
        </>
      ),
    },
    {
      key: '2',
      label: 'Category / Brand',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Channel',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '4',
      label: 'Supply Chain',
      children: 'Content of Tab Pane 4',
    },
  ];
  const onChange = (key: string) => {
    // console.log(key);
  };
  const getCurrentFormattedDate = (isStartDate: boolean) => {
    const today = new Date();
    const year = today.getFullYear();
    let month: any = today.getMonth() + 1;
    let day: any = today.getDate();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return `${year}-${month}-${day}`;
  };


  return (
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
  );
};

export default DashboardView2;
//FinancialAnalysis
