/* eslint-disable */

import request from 'utils/axios';
import { httpVerbs } from 'configs';
import { IDashboardMetricsReponse } from './types';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDashboardMetricsApi = async ({
  startDate,
  endDate,
}: { startDate?: string; endDate?: string } = {}): Promise<IDashboardMetricsReponse> => {
  // Construct the query string only if startDate or endDate is provided
  const queryParams = new URLSearchParams();
  
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);

  // Construct the final URL with query parameters (if any)
  const url = queryParams.toString() 
    ? `${BASE_URL}/dashboard?${queryParams.toString()}` 
    : `${BASE_URL}/dashboard`;

  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };

  const response = await request(params);
  return response;
};



export const getDashboardMetricsCategoryApi =
  async (): Promise<IDashboardMetricsReponse> => {
    const url = `${BASE_URL}/promotions/dashboard-category-metrics`;
    const params: any = {
      url: url,
      method: httpVerbs.GET,
    };
    const response = await request(params);
    return response;
  };
