import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import Direction from 'app/molecules/Direction';
import Layout from 'app/molecules/Layout/dashboard';
import { AbilityContext, ability } from 'app/atoms';
// import Permission from 'app/modules/Permission/Permission';
import landingPage from 'app/pages/landing';
import createPromotionPage from 'app/pages/createPromotionPage';
import PageNotFound from 'app/pages/PageNotFound';
// [IMPORT PAGES] < Needed for CRUD Generator
// [IMPORT ROUTE NAMES] < Needed for CRUD Generator
import {
  PROMOTION,
  REPORTING,
  USERLIST,
  VIEW,
  EDIT,
  APPROVALHIERARCHY,
  DASHBOARD,
} from 'configs/routeNames';

import Auth from 'app/modules/Auth/Auth';
import Reporting from 'app/modules/Reporting';
import AprovalHierarchy from 'app/modules/ApprovalHierarchy';
import FilterSelection from 'app/pages/filterSelection';
import AppPreLoad from 'app/modules/AppPreLoad/AppPreLoad';
import EditPromotion from 'app/modules/Promotion/editPromotion';
import CreatePromotion from 'app/modules/Promotion/createPromotion';
import UsersPage from 'app/pages/UserListing';
import ListView from 'app/modules/ListView/listView';
import ProtectedRoute from './ProtectedRoute';
import DashboardPage2 from 'app/pages/dashboardPage';
import { removeTokenFromLocal } from 'app/modules/Auth/ducks/services';
import { getPromotionsListSuccess } from 'app/modules/Promotion/ducks/slice';
import { createBrowserHistory, History } from 'history';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authActions } from 'app/modules/Auth/ducks/slice';

const history: History = createBrowserHistory();
const Pages = (props: RouteComponentProps): ReactElement => {
  const { path } = props.match;
  const { isTradeManager } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  window.onbeforeunload = function () {
    let isKeepMeSignedIn = localStorage.getItem('remember');
    if (isKeepMeSignedIn === 'false') {
      //logout here
      removeTokenFromLocal();
      // mixpanel?.reset();
      localStorage.removeItem('viewTour');
      dispatch(authActions.signOut());
      history.push('/');
      dispatch(
        getPromotionsListSuccess({
          data: {
            promotions: [],
            pageInfo: {
              size: 10,
              page: 1,
              totalItems: 0,
              totalPages: 0,
            },
          },
        }),
      );
    }
  };

  return (
    <div className="base base-2">
      <Direction>
        <Auth>
          <AppPreLoad>
            {/* <Permission> */}
            <AbilityContext.Provider value={ability}>
              <Layout>
                <Switch>
                  {/* Don't remove this comment. It will be used in CRUD generator. */}
                  <Route
                    exact
                    path={`${path}`}
                    component={isTradeManager ? landingPage : landingPage}
                  />
                  <Route
                    exact
                    path={`${path}calendar`}
                    component={landingPage}
                  />
                  <Route
                    exact
                    path={`${path}${PROMOTION}/create`}
                    component={CreatePromotion}
                  />
                  <Route
                    exact
                    path={`${path}${PROMOTION}/list`}
                    component={ListView}
                  />
                  <Route
                    exact
                    path={`${path}${PROMOTION}/:id`}
                    component={EditPromotion}
                  />
                  <Route
                    exact
                    path={`${path}${PROMOTION}/${EDIT}/:id`}
                    component={EditPromotion}
                  />
                  <Route
                    exact
                    path={`${path}${PROMOTION}/${VIEW}/:id`}
                    component={EditPromotion}
                  />
                  <Route
                    exact
                    path={`${path}${DASHBOARD}`}
                    component={DashboardPage2}
                  />
                  <ProtectedRoute
                    exact
                    path={`${path}${APPROVALHIERARCHY}`}
                    component={AprovalHierarchy}
                  />
                  <Route
                    exact
                    path={`${path}${REPORTING}`}
                    component={Reporting}
                  />
                  <ProtectedRoute
                    exact
                    path={`${path}${USERLIST}`}
                    component={UsersPage}
                  />
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Layout>
            </AbilityContext.Provider>
            {/* </Permission> */}
          </AppPreLoad>
        </Auth>
      </Direction>
    </div>
  );
};
Pages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.objectOf(PropTypes.any),
};
Pages.defaultProps = {
  match: {},
};
export default Pages;
