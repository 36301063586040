/* eslint-disable */

import React from 'react';
import { Column } from '@ant-design/plots';

const ProductPerformance: React.FC<{ data: any }> = ({ data }) => {
  const data2 = [
    {
      year: '2024',
      month: 'Jan',
      count: 531.76874,
    },
    {
      year: '2024',
      month: 'Feb',
      count: 3704.0667,
    },
    {
      year: '2024',
      month: 'Mar',
      count: 1832.1003,
    },
    {
      year: '2024',
      month: 'Apr',
      count: 210.29056,
    },
    {
      year: '2024',
      month: 'May',
      count: 4996.9644,
    },
    {
      year: '2024',
      month: 'Jun',
      count: 3464.0981,
    },
  ];

  const config = {
    data: data,
    isGroup: true,
    xField: 'month',
    yField: 'count',
    seriesField: 'year',
    columnWidthRatio: 0.8,
    height: 200,
    xAxis: {
      label: {
        formatter: (v: string) => `${v}`,
      },
      title: {
        text: 'Month',
      },
    },
    yAxis: {
      title: {
        text: 'Sum of Count',
      },
    },
    meta: {
      month: {
        alias: 'Month',
      },
      count: {
        alias: 'Total Count',
      },
    },
    color: ['#3268C7'], // Custom color

    label: {
      position: 'top', // Updated position to 'top' instead of 'middle'
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    legend: {
      position: 'top-right',
    }
    // Custom colors for the bars
  };

  return <Column {...config} />;
};

export default ProductPerformance;
