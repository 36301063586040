/* eslint-disable */

import React from 'react';
import {
  PieChart, Pie, Cell, Tooltip, Legend,
} from 'recharts';

const COLORS = ['#E6EDFF', '#005B96', '#164291', '#081A3A'];

const ChannelPerformanceChart: React.FC<{ data: any }> = ({ data }) => {
  let finalData = data.map((prop: any) => {
    return {
      name: prop.channel_name,
      value: prop.total_sales,
    };
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> {/* Flex container to center */}
      <PieChart width={250} height={250}>
        <Pie
          data={finalData}
          cx={125} // Center of the chart (half of the width)
          cy={125} // Center of the chart (half of the height)
          innerRadius={40} // Reduced inner radius
          outerRadius={60} // Reduced outer radius
          fill="#8884d8"
          dataKey="value"
          label
        >
          {data.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
};

export default ChannelPerformanceChart;
