/* eslint-disable */

import React from 'react';
import { List, Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const data2 = [
  { category: 'Grocery', percentage: '38.6%', change: '1.5%', changeType: 'down', color: '#FF4D4F' },
  { category: 'Electronics', percentage: '22.5%', change: '2.5%', changeType: 'down', color: '#FF4D4F' },
  { category: 'Crockery', percentage: '30.8%', change: '7.5%', changeType: 'up', color: '#52C41A' },
  { category: 'Toys', percentage: '8.1%', change: '2.5%', changeType: 'up', color: '#52C41A' },
  { category: 'Cosmetics', percentage: '3.1%', change: '0.5%', changeType: 'up', color: '#52C41A' },
];

  const CategoryBrandAnalysis: React.FC<{ data: any }> = ({ data }) => {

    const cba = [
      {
          "category": "HAIR CARE",
          "percentage": "1.8%",
          "change": "3.6%",
          "changeType": "up",
          "color": "#5587C7"
      },
      {
          "category": "FABRIC CLEANING",
          "percentage": "6.8%",
          "change": "0.3%",
          "changeType": "up",
          "color": "#005B96"
      },
      {
          "category": "SKIN CARE",
          "percentage": "1.3%",
          "change": "4.8%",
          "changeType": "up",
          "color": "#E0944D"
      }
  ]
  return (
    <div>
      
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item:any) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <span
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: item.color,
                    display: 'inline-block',
                    borderRadius: '50%',
                  }}
                />
              }
              title={<span>{item.category}</span>}
            />
            <div>
              <span style={{ marginRight: '10px' }}>{item.percentage}</span>
              <Statistic
                value={item.change}
                precision={1}
                valueStyle={{ color: item.color, fontSize: '16px' }}
                prefix={
                  item.changeType === 'up' ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )
                }
                suffix="%"
              />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default CategoryBrandAnalysis;
