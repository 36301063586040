/* eslint-disable */
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SalesAndRevenueTrends: React.FC<{ data: any }> = ({ data }) => {



  // Transforming the data for plotting actual vs planned
  const transformedData = data.map((item: any) => ({
    month: item.month,
    actual: item.actual_sales,
    planned: item.planned_sales
  }));

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart data={transformedData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={(value) => `${(value / 1000).toFixed(0)}K`} />
        <Tooltip formatter={(value) => `${(value as number / 1000).toFixed(2)}K`} />
        {/* Display legend below the chart */}
        <Legend
          align="center"
          verticalAlign="bottom"
          iconType="circle"
          wrapperStyle={{ paddingTop: 10 }}
        />
        <Line type="linear" dataKey="actual" stroke="#3268C7" activeDot={{ r: 8 }} name="Actual" />
        <Line type="linear" dataKey="planned" stroke="#60A5FA" name="Planned" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SalesAndRevenueTrends;
