/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Area } from '@ant-design/charts';

const RetailerPerformance: React.FC<{ data: any }> = ({ data }) => {



  const config = {
    data : data,
    xField: 'Date',
    yField: 'scales',
    xAxis: {
      type: 'time',
      tickCount: 5,
    },
    yAxis: {
      label: {
        formatter: (v:any) => `${v}k`, // Custom y-axis label
      },
    },
    tooltip: {
      showCrosshairs: true, // Show crosshairs for better data visibility
    },
    areaStyle: { fill: 'l(270) 0:rgba(91, 143, 249, 0.85) 0.5:rgba(91, 143, 249, 0.25) 1:rgba(91, 143, 249, 0)' }, // Gradient style
    height: 200, // Set the chart height

  };

  return <Area {...config} />;
};

export default RetailerPerformance;
