/* eslint-disable */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Destructure the `data` prop directly in the component
const TopPerformingPromotions: React.FC<{ data: any }> = ({ data }) => {
  console.log('data: TopPerformingPromotions', data);

  let dd = [
    {
      promotion_id: 'EWSBSF24AAFP',
      planned_volume: 0.00324,
      planned_cost: 76.32,
      planned_roi: 0,
    },
    {
      promotion_id: 'EWSBSF24AAFP',
      planned_volume: 3.166176,
      planned_cost: 101196.32,
      planned_roi: 0,
    },
    {
      promotion_id: 'EWSBSF24AAFP',
      planned_volume: 12.800324,
      planned_cost: 560487.44,
      planned_roi: 0,
    },
    {
      promotion_id: 'EWSBSF24AAFP',
      planned_volume: 3.051058,
      planned_cost: 150868.72,
      planned_roi: 0,
    },
    {
      promotion_id: 'EWSBSF24AAFP',
      planned_volume: 148.9096,
      planned_cost: 10287719,
      planned_roi: 0,
    },
  ];

  let dataOBarchart = data.map((item:any, index:any) => {
    return {
      name: `Promo ${index + 1}`, // Display more concise promotion names
      Actual: item.planned_volume, // Use 'planned_volume' as the 'Actual' value
    };
  });

  return (
    <ResponsiveContainer width="100%" height={260}>
      <BarChart data={dataOBarchart}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend
          align="center"
          verticalAlign="bottom"
          iconType="circle"
          wrapperStyle={{ paddingTop: 10 }} // Adds padding to match the Figma design
        />
        <Bar dataKey="Actual" stackId="a" fill="#3268C7" name="Actual" />
        <Bar dataKey="Planned" stackId="a" fill="#DDE4F0" name="Planned" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TopPerformingPromotions;
