/* eslint-disable */

import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';



const SupplyChainImpact: React.FC<{ data: any }> = ({ data }) => {


  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 30,
        }}
      >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="store"  />
        <YAxis label={{ value: 'Value', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend verticalAlign="bottom" />
        <Bar dataKey="demand" fill="#3268C7" radius={[0, 0, 0, 0]} />
        <Bar dataKey="supply" fill="#B9C7E3" radius={[0, 0, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SupplyChainImpact;
